import scrollDirection from "./utils/scroll-direction";
import createScrollObserver from "./utils/scroll-observer";
import "./components/cyc-card/cyc-card";
import Glide from "@glidejs/glide";

let eventScroll = scrollDirection(window);

document
  .querySelectorAll("[data-direction]")
  .forEach((el) => eventScroll.push(el));

document.querySelectorAll("[href]").forEach((link) => {
  let href = link.getAttribute("href");
  if (/^#(.+)/.test(href)) {
    if (!document.querySelector(href)) {
      link.setAttribute("href", __dirsite + href);
    }
  }
});

document.querySelectorAll("[data-toggle]").forEach((el) => {
  let toggle = el.dataset.toggle;
  el.addEventListener("click", () => {
    document
      .querySelectorAll(toggle)
      .forEach((el) => el.classList.toggle("--active"));
  });
});

let scrollObserver = createScrollObserver();

document
  .querySelectorAll("[data-scroll],[data-src]")
  .forEach((el) => scrollObserver.observe(el));

document.querySelector(".slides .glide") &&
  new Glide(".slides .glide", {
    animationDuration: 1000,
    animationDuration: 1000,
    autoplay: 5000,
    gap: 0,
  }).mount();

document.querySelector(".products.-slide .glide") &&
  new Glide(".products.-slide .glide", {
    perView: 5,
    gap: 0,
    ...(document.querySelectorAll(".products.-slide .glide .glide__slide")
      .length > 5
      ? { animationDuration: 1000, autoplay: 2000 }
      : {}),
    breakpoints: {
      620: {
        perView: 2,
      },
      420: {
        perView: 1,
      },
    },
  }).mount();
