import { h, customElement, useProp, useEffect, useState } from "atomico";
import style from "./cyc-card.css";

function CyCCard({ image, content, label, labelOpen, price }) {
  let [show, setShow] = useProp("show");
  let [ref, setRef] = useState({ height: 100 });

  useEffect(() => {
    let { current } = ref;
    current.innerHTML = content;
    let { clientHeight } = current;
    setRef({ height: clientHeight });
  }, [content]);

  return (
    <host shadowDom>
      <style>{style}</style>
      <div class="image">
        <img src={image} alt={label} />
      </div>
      <div class="group">
        <h3 class="label">{label}</h3>
        <div
          class="content"
          style={`height:${
            show ? ref.height + 40 : ref.height > 45 ? 45 : ref.height
          }px`}
        >
          <div ref={ref}></div>
          {ref.height > 45 && <div class="layer"></div>}
        </div>
        <div class="footer">
          <button
            class={`button ${ref.height > 45 ? "" : "--hide"}`}
            onclick={() => setShow(!show)}
          >
            <div class="icon">
              <svg width="10" height="10" viewBox="0 0 10 10">
                <path
                  id="Unión_1"
                  data-name="Unión 1"
                  d="M3.5,10V6.5H0v-3H3.5V0h3V3.5H10v3H6.5V10Z"
                  fill="#f3f1ec"
                />
              </svg>
            </div>
            {labelOpen}
          </button>
          <span class="price">{price}</span>
        </div>
      </div>
    </host>
  );
}

CyCCard.props = {
  price: { type: String },
  labelOpen: { type: String, value: "ver más" },
  label: { type: String },
  image: { type: String },
  content: { type: String },
  show: { type: Boolean, value: false },
};

customElement("cyc-card", CyCCard);
